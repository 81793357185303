import { excelDateToJSDate } from "utils/formatter";

export const paymentMapperToBackend = (items, year) => {
  const skipped = [];
  const mappedPayments = items
    .map((item) => {
      let details = item["Детали платежи"];
      let pin = item["JSHSHIR-KOD"];
      // pin = (pin || "").replace(/\D/g, "");
      // Check if details is a string before using indexOf
      if (!pin || pin?.length !== 14) {
        if (typeof details === "string") {
          let searchString = "курс талабаси ";
          try {
            pin = details.substring(
              details.indexOf(searchString) + searchString.length,
              details.indexOf(searchString) + searchString.length + 14
            );

            // Clean the extracted pin
            pin = pin.replace(/\D/g, "");

            if (pin.length !== 14) {
              skipped.push({
                item,
                reason: "Invalid JSHSHIR-KOD from details",
              });
              return null; // Skip this item
            }
          } catch (error) {
            skipped.push({ item, reason: "Invalid JSHSHIR-KOD" });
            return null; // Skip this item
          }
        } else {
          skipped.push({ item, reason: "Invalid JSHSHIR-KOD" });
          return null; // Skip this item
        }
      }

      // Convert pin to number to ensure it's numeric
      const numericPin = parseInt(pin, 10);

      // Additional validation
      if (isNaN(numericPin)) {
        skipped.push({ item, reason: "PIN is not a valid number" });
        return null;
      }

      return {
        personIdentityNumber: numericPin,
        academicYear: year,
        paymentNo: item["№"],
        docType: item["Тип документа"],
        docNo: item["№ докум."],
        date: excelDateToJSDate(item["Дата"]),
        sum: item["Сумма"],
        payer: item["Плательщик"],
        receiver: item["Получатель"],
        details: details,
        type: item["Вид"],
        inCharge: item["Mas'ul xodimning ISMI"],
      };
    })
    .filter((item) => item !== null);
  return { mappedPayments, skipped };
};
