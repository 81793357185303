export const contractMapperToBackend = (students, year) => {
  return students.map((student) => {
    return {
      name: student["Талаба Ф.И.Ш"].split(" ")[1],
      surname: student["Талаба Ф.И.Ш"].split(" ")[0],
      middleName: student["Талаба Ф.И.Ш"].split(" ").slice(2).join(" "),
      personIdentityNumber: student["Талаба ЖШШИРи"],
      contractSumType: student["Шартнома суммаси тури"],
      contractType: student["Шартнома тури"],
      educationYear: student[" Таълим босқичи"],
      contractSum: student["Шартномасуммаси"],
      contractState: student["Шартнома ҳолати"],
      contractNo: student["Шартнома рақами"],
      academicYear: year,
    };
  });
};
