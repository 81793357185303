export const studentMapperToBackend = (students, year) => {
  const skippedStudents = [];
  const seenIdentityNumbers = new Set();

  const mappedStudents = students
    .map((student) => {
      if (!student["JSHSHIR-KOD"] || isNaN(student["JSHSHIR-KOD"])) {
        skippedStudents.push({
          student,
          reason: !student["JSHSHIR-KOD"]
            ? "Missing JSHSHIR-KOD"
            : "Invalid JSHSHIR (not a number)",
        });
        return null;
      }

      // Check for duplicate personIdentityNumber
      const identityNumber = student["JSHSHIR-KOD"];
      if (seenIdentityNumbers.has(identityNumber)) {
        skippedStudents.push({
          student,
          reason: `Duplicate JSHSHIR-KOD: ${identityNumber}`,
        });
        return null;
      }

      // Add to seen set if unique
      seenIdentityNumbers.add(identityNumber);

      return {
        orderNoAndDate: "" + student["BUYRUQ RAQAMI VA SANASI"],
        name: student["FISH"].split(" ")[1],
        surname: student["FISH"].split(" ")[0],
        middleName: student["FISH"].split(" ").slice(2).join(" "),
        personIdentityNumber: student["JSHSHIR-KOD"],
        returnPin: "V" + student["JSHSHIR-KOD"],
        educationYear: "" + student["Kurs"],
        faculty: student["Fakultet"],
        groupNumber: student["Guruh"],
        educationType: student["Ta’lim turi"],
        educationMode: student["Ta’lim shakli"],
        educationSpecialty: student["MUTAXASSISLIK"],
        academicYear: year,
        passportID: student["PASPORT RAQAMI"],
        additionalFaculty: student["Qo'shma Fakultet"],
      };
    })
    .filter((student) => student !== null);

  return {
    mappedStudents,
    skippedStudents,
  };
};
