import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Login from "./views/user/login";
import Error from "views/error";
import Unauthorized from "views/unauthorized";
import AppLayout from "layout/AppLayout";
import Blank from "views/app/Blank";
import UniversitiesList from "views/app/students/studentsList";
import UsersList from "views/app/users/usersList";
import AddUser from "views/app/users/add";
import ApplicationsList from "views/app/applications/applicationsList";
import { PrivateRoute } from "auth/privateRoute";
import Settings from "views/app/settings/settings";
import AddStudent from "views/app/students/add";
import MainDashboard from "views/app/dashboard/mainDashboard";
import DashboardStudentsList from "views/app/dashboard/dashboardStudentsList";
import ViewStudent from "views/app/students/view";
import Balance from "views/app/Balance";
import ContractsList from "views/app/contract/contractList";
import PaymentsList from "views/app/payments/paymentList";

function App() {
  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />

          <Route path="/error" element={<Error />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route
            path="/app/dashboard"
            element={
              <PrivateRoute>
                <MainDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/app"
            element={
              <PrivateRoute>
                <MainDashboard />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/students"
            element={
              <PrivateRoute>
                <UniversitiesList />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/students/add"
            element={
              <PrivateRoute>
                <AddStudent />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/students/filtered"
            element={
              <PrivateRoute>
                <DashboardStudentsList />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/students/edit/:id"
            element={
              <PrivateRoute>
                <AddStudent />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/students/:id"
            element={
              <PrivateRoute>
                <ViewStudent />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/contracts"
            element={
              <PrivateRoute>
                <ContractsList />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/payments"
            element={
              <PrivateRoute>
                <PaymentsList />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/users"
            element={
              <PrivateRoute>
                <UsersList />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/users/add"
            element={
              <PrivateRoute>
                <AddUser />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/users/:id"
            element={
              <PrivateRoute>
                <AddUser />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/settings"
            element={
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/applications"
            element={
              <PrivateRoute>
                <ApplicationsList />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/balance"
            element={
              <PrivateRoute>
                <Balance />
              </PrivateRoute>
            }
          />

          <Route path="*" element={<Error />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
