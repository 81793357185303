/* eslint-disable react/no-array-index-key */
import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, CardTitle, Progress } from "reactstrap";

const ProfileStatusesSpan = ({ cardClass = "h-100", title, data, double }) => {
  const navigate = useNavigate();
  let dataIn = data.data ? data.data : data;
  return (
    <>
      {dataIn.map((s, index) => {
        return (
          <div key={index} className={`mb-4 ${double ? "col-6" : ""}`}>
            <p
              className="mb-2"
              onDoubleClick={() =>
                navigate(
                  `/app/d-students/filtered?faculty=${title}&type=unpaid`
                )
              }
            >
              {index + 1}. {s.title}
              <span className="float-right">
                <span className="text-green">
                  {s.paidPercentage}% ({s.paid})
                </span>{" "}
                |{" "}
                <span className="text-red">
                  {s.unpaidPercentage}% ({s.unpaid})
                </span>
              </span>
            </p>
            <Progress
              className={
                s.total === 0 ? `progress-bar-empty` : `progress-bar-men`
              }
              value={(s.women / s.total) * 100}
            />
          </div>
        );
      })}
    </>
  );
};
export default ProfileStatusesSpan;
