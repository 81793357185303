import React, { useEffect, useState } from "react";
import { Button, Row, Card } from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axiosClient";
import { useToken } from "auth/useToken";
import { useUser } from "auth/useUser";
import SaveCandidates from "./saveCandidatesModal";
import { currencyFormatter } from "utils/formatter";
import { toast } from "react-toastify";
import Select from "react-select";

const StudentsList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tutor, setTutor] = useState("");
  const [faculty, setFaculty] = useState("Tanlang");
  const [facultyList, setFacultyList] = useState([]);
  const [word, setWord] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(null);
  const [token, setToken] = useToken();
  const [pages, setPages] = useState(null);
  const [importModal, setImportModal] = useState(false);
  const user = useUser();

  const getStudents = async () => {
    if (!faculty || faculty === "Tanlang") {
      return toast.error("Fakultetni tanlang");
    }
    setLoading(true);
    axios
      .get(
        `${
          user.role === "Foydalanuvchi"
            ? `/api/students/${user.username}?per_page=50&page=${page}&faculty=${faculty}`
            : user.role === "Dekanat"
            ? `/api/dean-students/${user.username}?per_page=50&page=${page}&faculty=${faculty}`
            : `/api/students?type=all&per_page=50&page=${page}&faculty=${faculty}`
        }`
      )
      .then((result) => {
        setData([...result.data.data]);
        setCount(result.data.count);
        setPages(result.data.pages);
        setLoading(false);
      });
  };

  const getFaculties = async () => {
    axios.get(`/api/unique-faculties`).then((result) => {
      if (result.data.data) {
        setFacultyList(
          result.data.data.map((item) => {
            return { label: item, value: item };
          })
        );
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    let isMounted = true;
    getFaculties();
    return () => {
      isMounted = false;
    };
  }, []);

  const navigate = useNavigate();

  const onSearchKey = (e) => {
    if (e.key === "Enter") {
      setLoading(true);
      setPage(1);
      handleSearch(word);
    }
  };
  const onTutorSearchKey = (e) => {
    if (e.key === "Enter") {
      setLoading(true);
      setPage(1);
      handleSearch("");
    }
  };

  const toggleImportModal = () => {
    setImportModal(!importModal);
  };

  const handleSearch = () => {
    ///

    // console.log(word);
    if (!faculty || faculty === "Tanlang") {
      return toast.error("Fakultetni tanlang");
    }
    axios
      .get(
        `${
          user.role === "Foydalanuvchi"
            ? `/api/students/${
                user.username
              }?search=${word}&per_page=50&page=${1}&faculty=${faculty}`
            : user.role === "Dekanat"
            ? `/api/dean-students/${user.username}?${
                word === "" ? "" : `search=${word}&`
              }per_page=50&page=${1}&faculty=${faculty}`
            : tutor === ""
            ? `/api/students?${
                word === "" ? "" : `search=${word}&`
              }per_page=50&page=${1}&faculty=${faculty}`
            : `/api/students/${tutor}?${
                word === "" ? "" : `search=${word}&`
              }per_page=50&page=${1}&faculty=${faculty}`
        }`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        setData(result.data.data);
        setCount(result.data.count);
        setPages(result.data.pages);
        setLoading(false);
      });
  };

  const handleLoadMore = () => {
    if (!faculty || faculty === "Tanlang") {
      return toast.error("Fakultetni tanlang");
    }
    axios
      .get(
        `${
          user.role === "Foydalanuvchi"
            ? `/api/students/${user.username}?${
                word === "" ? "" : `search=${word}&`
              }per_page=50&page=${page + 1}&faculty=${faculty}`
            : user.role === "Dekanat"
            ? `/api/dean-students/${user.username}?${
                word === "" ? "" : `search=${word}&`
              }per_page=50&page=${page + 1}&faculty=${faculty}`
            : tutor === ""
            ? `/api/students?${
                word === "" ? "" : `search=${word}&`
              }per_page=50&page=${page + 1}&faculty=${faculty}`
            : `/api/students/${tutor}?${
                word === "" ? "" : `search=${word}&`
              }per_page=50&page=${page + 1}&faculty=${faculty}`
        }`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        setData([...data, ...result.data.data]);
        setCount(result.data.count);
        setPage(page + 1);
        setLoading(false);
      });
  };

  const handleDownload = () => {
    if (!faculty || faculty === "Tanlang") {
      return toast.error("Fakultetni tanlang");
    }
    axios
      .post(
        `${
          user.role === "Foydalanuvchi"
            ? `/api/download/students/${user.username}?${
                word === "" ? "" : `search=${word}`
              }&faculty=${faculty}`
            : tutor === ""
            ? `/api/download/students?${
                word === "" ? "" : `search=${word}`
              }&faculty=${faculty}`
            : `/api/download/students/${tutor}?${
                word === "" ? "" : `search=${word}`
              }&faculty=${faculty}`
        }`,
        {
          all: true,
          title:
            user.role === "Tutor"
              ? user.username
              : tutor === ""
              ? "AndMI"
              : tutor,
        },
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = url;
        link.download = "Talabalar.xlsx"; //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  };

  const handleDownloadCsv = () => {
    if (!faculty || faculty === "Tanlang") {
      return toast.error("Fakultetni tanlang");
    }
    axios
      .post(
        `${
          user.role === "Foydalanuvchi"
            ? `/api/download-csv/students/${user.username}?${
                word === "" ? "" : `search=${word}`
              }&faculty=${faculty}`
            : tutor === ""
            ? `/api/download-csv/students?${
                word === "" ? "" : `search=${word}`
              }&faculty=${faculty}`
            : `/api/download-csv/students/${tutor}?${
                word === "" ? "" : `search=${word}`
              }&faculty=${faculty}`
        }`,
        {
          all: true,
          title:
            user.role === "Tutor"
              ? user.username
              : tutor === ""
              ? "AndMI"
              : tutor,
        },
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = url;
        link.download = "Talabalar.csv"; //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  };

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>Talabalar Ro'yxati</h1>
              {(user.role === "Admin" || user.role === "Foydalanuvchi") && (
                <div className="text-zero top-right-button-container">
                  {/* {user.role !== "Dekanat" && (
                    <Button
                      color="primary"
                      className="top-right-button"
                      onClick={() => navigate("add")}
                    >
                      Yangi Qo'shish
                    </Button>
                  )} */}
                  {user.role === "Admin" && (
                    <Button
                      color="primary"
                      className="top-right-button ml-2"
                      onClick={toggleImportModal}
                    >
                      Exceldan yuklash
                    </Button>
                  )}
                  {/* {user.role !== "Dekanat" && (
                    <Button
                      color="primary"
                      className="top-right-button ml-2"
                      onClick={handleDownload}
                    >
                      Excelda olish
                    </Button>
                  )}
                  {user.role === "Admin" && (
                    <Button
                      color="primary"
                      className="top-right-button ml-2"
                      onClick={handleDownloadCsv}
                    >
                      Csvda olish
                    </Button>
                  )} */}
                </div>
              )}
            </div>
            <div className="mb-2">
              <div
                className="d-block d-md-inline-block pt-1"
                hidden={
                  user.role === "Foydalanuvchi" || user.role === "Dekanat"
                }
              >
                <div className="d-inline-block mb-1 d-flex">
                  <Select
                    className="react-select mr-2"
                    classNamePrefix="react-select"
                    name="form-field-name"
                    value={{ label: faculty, value: faculty }}
                    onChange={(t) => setFaculty(t.value)}
                    options={facultyList}
                  />
                  <span onClick={getStudents} className="icon-btn">
                    <i className="simple-icon-arrow-right-circle" />
                  </span>
                </div>
              </div>{" "}
              &nbsp;
              <div className="d-block d-md-inline-block pt-1 pl-3">
                <div className="search-sm d-inline-block mb-1 ">
                  <input
                    type="text"
                    name="keyword"
                    id="search"
                    placeholder={"Search"}
                    value={word}
                    onChange={(e) => setWord(e.target.value.toLowerCase())}
                    onKeyPress={(e) => onSearchKey(e)}
                  />
                </div>
              </div>
              &nbsp; Jami talabalar: {count}
            </div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <div className="loading" hidden={!loading} />
          {!loading && data.length === 0 ? (
            <p>Ro'yxat bo'sh</p>
          ) : (
            data.map((student) => (
              <Colxx xxs="12" key={student._id} className="mb-1">
                <Card className="d-flex flex-row">
                  <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                    <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center student-card">
                      <NavLink to={`${student._id}`} className="w-30 w-sm-100">
                        <p className="list-item-heading mb-1 truncate">
                          {`${student.name} ${student.surname}`}
                        </p>
                      </NavLink>
                      <p className="mb-1 text-muted text-small w-30 w-sm-100">
                        {`${student.faculty}`}
                        <br />
                        {`${student.groupNumber} (${student.educationYear}-kurs)`}
                        <br />
                        {`${student.educationMode} ${student.educationType}`}
                      </p>
                      <p className="mb-1 text-muted text-small w-15 w-sm-100">
                        {student.contractNo}
                        <br />
                        {student.contractSumType}
                        <br />
                        {student.contractType}
                      </p>
                      <p className="mb-1 text-muted text-small w-15 w-sm-100">
                        {currencyFormatter("" + student.paid)}
                        <br />({currencyFormatter("" + student.contractSum)})
                      </p>
                      <p className="mb-1 text-muted text-small w-15 w-sm-100">
                        <strong>
                          Qarz:{" "}
                          {(
                            100 -
                            (student.paid / student.contractSum) * 100
                          ).toFixed(2)}
                          %
                        </strong>{" "}
                        <br />
                        {currencyFormatter(
                          student.contractSum - student.paid + ""
                        )}
                      </p>
                    </div>
                  </div>
                </Card>
              </Colxx>
            ))
          )}
          {!loading && data.length !== 0 && pages !== page && (
            <div className="col-12 mt-3">
              <Button
                color="primary"
                className="top-right-button"
                onClick={handleLoadMore}
              >
                Ko'proq...
              </Button>
            </div>
          )}
          {importModal && (
            <div className="in-modal">
              <div className="modal-overlay"></div>
              <div className="in-modal-content">
                <SaveCandidates toggle={toggleImportModal} />
              </div>
            </div>
          )}
        </Row>
      </div>
    </AppLayout>
  );
};

export default StudentsList;
