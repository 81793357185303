import React, { useEffect, useState, useRef } from "react";
import axios from "axiosClient";
import { read, utils } from "xlsx";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import { useToken } from "auth/useToken";
import { toast } from "react-toastify";
import { studentMapperToBackend } from "utils/mappers/students";

const SaveCandidates = ({ toggle }) => {
  const navigate = useNavigate();

  const [year, setYear] = useState("");
  const [candidates, setCandidates] = useState([]);
  const [skippedStudents, setSkippedStudents] = useState([]);
  const [candidatesLoading, setCandidatesLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSending(true);

    try {
      if (errorMessage === "") {
        axios
          .post(`/api/upload-students`, { students: candidates })
          .then((result) => {
            // console.log(result.data);
            setSent(true);
            setSending(false);
            toast.success("Hammasi saqlandi");
            // toggle();
            // navigate(0);
          })
          .catch((err) => {
            setErrorMessage(err.response.data);
            toast.error(err.response.data);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      {!loading && (
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-4">
              <CardBody>
                <Form>
                  <Row>
                    <Col md="12">
                      <br />
                      O'quv yilini kiriting:
                      <FormGroup row>
                        <Colxx sm="9">
                          <Input
                            value={year}
                            type="text"
                            onChange={(e) => setYear(e.target.value)}
                          />
                        </Colxx>
                      </FormGroup>
                      <>
                        {year !== "" && (
                          <>
                            Iltimos .xlsx faylni yuklang:
                            <FormGroup row>
                              <Colxx sm="9">
                                <Input
                                  type="file"
                                  accept=".xlsx"
                                  onChange={async (e) => {
                                    const files = e.target.files;
                                    if (files) {
                                      setCandidatesLoading(true);
                                      const wb = read(
                                        await files[0].arrayBuffer()
                                      );
                                      const data = utils.sheet_to_json(
                                        wb.Sheets[wb.SheetNames[0]]
                                      );
                                      const result = studentMapperToBackend(
                                        data,
                                        year
                                      );
                                      // console.log(data);
                                      // const jsonData = JSON.stringify(
                                      //   studentMapperToBackend(data),
                                      //   null,
                                      //   2
                                      // );
                                      // const blob = new Blob([jsonData], {
                                      //   type: "application/json",
                                      // });
                                      // const url = URL.createObjectURL(blob);

                                      // const a = document.createElement("a");
                                      // a.href = url;
                                      // a.download = "data.json";
                                      // document.body.appendChild(a);
                                      // a.click();

                                      // document.body.removeChild(a);
                                      // URL.revokeObjectURL(url);
                                      setCandidates(result.mappedStudents);
                                      setSkippedStudents(
                                        result.skippedStudents
                                      );
                                      setCandidatesLoading(false);
                                    }
                                  }}
                                />
                              </Colxx>
                            </FormGroup>
                            {/* <>{JSON.stringify(candidates)}</> */}
                            {candidatesLoading ? (
                              <p>O'qilmoqda...</p>
                            ) : (
                              <p>
                                Fayldagi talabalar soni: {candidates.length}
                                {skippedStudents.length > 0 && (
                                  <p>
                                    JSHSHIR raqami yo'q talabalar soni:{" "}
                                    {skippedStudents.length}
                                  </p>
                                )}
                              </p>
                            )}
                          </>
                        )}
                      </>
                      {sending && (
                        <span className="btn btn-warning">Yuklanmoqda...</span>
                      )}
                      {sent && (
                        <span className="btn btn-success">Yuklandi!</span>
                      )}
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      )}
      <div>
        <Button
          color="light"
          className="top-right-button"
          onClick={() => toggle()}
        >
          Yopish
        </Button>
        {!sent && (
          <Button
            color="primary"
            className="ml-2 top-right-button"
            onClick={handleSubmit}
            disabled={candidates.length === 0 || sending}
          >
            Saqlash
          </Button>
        )}
      </div>
      &nbsp;
      <div>{errorMessage}</div>
    </div>
  );
};

export default SaveCandidates;
