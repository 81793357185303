import React, { useEffect, useState } from "react";
import { Button, Row, Card } from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axiosClient";
import { useToken } from "auth/useToken";
import { useUser } from "auth/useUser";
import SaveContracts from "./saveContractModal";
import { currencyFormatter } from "utils/formatter";

const ContractsList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tutor, setTutor] = useState("");
  const [word, setWord] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(null);
  const [token, setToken] = useToken();
  const [pages, setPages] = useState(null);
  const [importModal, setImportModal] = useState(false);
  const user = useUser();

  useEffect(() => {
    let isMounted = true;
    axios.get(`${`/api/contracts?per_page=50&page=${1}`}`).then((result) => {
      if (isMounted) {
        setData(result.data.data);
        setCount(result.data.count);
        setPages(result.data.pages);
        setLoading(false);
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  const navigate = useNavigate();

  const onSearchKey = (e) => {
    if (e.key === "Enter") {
      setLoading(true);
      setPage(1);
      handleSearch(word);
    }
  };

  const toggleImportModal = () => {
    setImportModal(!importModal);
  };

  const handleSearch = () => {
    ///

    console.log(word);
    axios
      .get(
        `${`/api/contracts/${tutor}?${
          word === "" ? "" : `search=${word}&`
        }per_page=50&page=${1}`}`
      )
      .then((result) => {
        setData(result.data.data);
        setCount(result.data.count);
        setPages(result.data.pages);
        setLoading(false);
      });
  };

  const handleLoadMore = () => {
    axios
      .get(
        `${`/api/contracts/${tutor}?${
          word === "" ? "" : `search=${word}&`
        }per_page=50&page=${page + 1}`}`
      )
      .then((result) => {
        setData([...data, ...result.data.data]);
        setCount(result.data.count);
        setPage(page + 1);
        setLoading(false);
      });
  };

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>Shartnomalar Ro'yxati</h1>
              {(user.role === "Admin" || user.role === "Foydalanuvchi") && (
                <div className="text-zero top-right-button-container">
                  {/* {user.role !== "Dekanat" && (
                    <Button
                      color="primary"
                      className="top-right-button"
                      onClick={() => navigate("add")}
                    >
                      Yangi Qo'shish
                    </Button>
                  )} */}
                  {user.role === "Admin" && (
                    <Button
                      color="primary"
                      className="top-right-button ml-2"
                      onClick={toggleImportModal}
                    >
                      Exceldan yuklash
                    </Button>
                  )}
                  {/* {user.role !== "Dekanat" && (
                    <Button
                      color="primary"
                      className="top-right-button ml-2"
                      onClick={handleDownload}
                    >
                      Excelda olish
                    </Button>
                  )}
                  {user.role === "Admin" && (
                    <Button
                      color="primary"
                      className="top-right-button ml-2"
                      onClick={handleDownloadCsv}
                    >
                      Csvda olish
                    </Button>
                  )} */}
                </div>
              )}
            </div>
            <div className="mb-2">
              {/* <div
                className="d-block d-md-inline-block pt-1"
                hidden={user.role === "Foydalanuvchi" || user.role === "Dekanat"}
              >
                <div className="search-sm d-inline-block mb-1 ">
                  <input
                    type="text"
                    name="tutor"
                    id="tutor"
                    placeholder={"Foydalanuvchi"}
                    value={tutor}
                    onChange={(e) => setTutor(e.target.value)}
                    onKeyPress={(e) => onTutorSearchKey(e)}
                  />
                </div>
              </div>{" "} */}
              &nbsp;
              <div className="d-block d-md-inline-block pt-1">
                <div className="search-sm d-inline-block mb-1 ">
                  <input
                    type="text"
                    name="keyword"
                    id="search"
                    placeholder={"Search"}
                    value={word}
                    onChange={(e) => setWord(e.target.value.toLowerCase())}
                    onKeyPress={(e) => onSearchKey(e)}
                  />
                </div>
              </div>
              &nbsp; Jami shartnomalar: {count}
            </div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <div className="loading" hidden={!loading} />
          {!loading && data.length === 0 ? (
            <p>Ro'yxat bo'sh</p>
          ) : (
            data.map((student) => (
              <Colxx xxs="12" key={student._id} className="mb-1">
                <Card className="d-flex flex-row">
                  <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                    <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center student-card">
                      <p className="mb-1 text-muted text-small w-15 w-sm-100">
                        {`${student.personIdentityNumber}`}
                      </p>
                      <NavLink to={`#`} className="w-40 w-sm-100">
                        <p className="list-item-heading mb-1 truncate">
                          {`${student.name} ${student.surname}`}
                        </p>
                      </NavLink>
                      <p className="mb-1 text-muted text-small w-15 w-sm-100">
                        {`${student.contractType} - ${student.contractSumType}`}
                      </p>
                      <p className="mb-1 text-muted text-small w-15 w-sm-100">
                        {student.educationYear}
                      </p>
                      <p className="mb-1 text-muted text-small w-15 w-sm-100">
                        {`${currencyFormatter("" + student.contractSum)}`}
                      </p>
                      <p className="mb-1 text-muted text-small w-15 w-sm-100">
                        {`${student.contractState}`}
                      </p>
                    </div>
                  </div>
                </Card>
              </Colxx>
            ))
          )}
          {!loading && data.length !== 0 && pages !== page && (
            <div className="col-12 mt-3">
              <Button
                color="primary"
                className="top-right-button"
                onClick={handleLoadMore}
              >
                Ko'proq...
              </Button>
            </div>
          )}
          {importModal && (
            <div className="in-modal">
              <div className="modal-overlay"></div>
              <div className="in-modal-content">
                <SaveContracts toggle={toggleImportModal} />
              </div>
            </div>
          )}
        </Row>
      </div>
    </AppLayout>
  );
};

export default ContractsList;
