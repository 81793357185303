import { CHART_COLORS, CHART_COLORS_ALPHA } from "./Constants";

export const livingDataConstruct = (data) => {
  let result = [];
  let resultData = [];
  let resultUnpaidData = [];
  let labels = [];
  let titles = [];
  for (let i = 0; i < data.length; i++) {
    let item = data[i];
    labels.push(i + 1);
    resultData.push(item.totalPaidStudents);
    resultUnpaidData.push(item.totalDebtStudents);
    result.push({
      title: item.faculty,
      total: item.totalStudents,
      women: item.totalDebtStudents,
      paid: item.totalPaidStudents,
      paidPercentage: item.paidStudentsPercentage,
      unpaid: item.totalDebtStudents,
      unpaidPercentage: item.debtStudentsPercentage,
    });
  }
  return {
    data: result,
    chartData: {
      labels: labels,
      datasets: [
        {
          data: resultData,
          borderColor: CHART_COLORS.green,
          backgroundColor: CHART_COLORS_ALPHA.green,
          borderWidth: 2,
          borderRadius: 5,
          borderSkipped: false,
        },
        {
          data: resultUnpaidData,
          borderColor: CHART_COLORS.red,
          backgroundColor: CHART_COLORS_ALPHA.red,
          borderWidth: 2,
          borderRadius: 5,
          borderSkipped: false,
        },
      ],
      titles: titles,
    },
  };
};
