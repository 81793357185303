export const currencyFormatter = (value = "", symbol = "") => {
  if (value) {
    // console.log("value", value);
    return `${value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${
      symbol === "" ? symbol : ` ${symbol}`
    }`;
  } else {
    return null;
  }
};

export const excelDateToJSDate = (excelDate) => {
  // Excel dates start from 1900-01-01, so we adjust the number accordingly
  if (typeof excelDate === "string") return excelDate;
  let excelStartDate = new Date(1900, 0, 1); // Jan 1, 1900
  let jsDate = new Date(excelStartDate.getTime() + (excelDate - 2) * 86400000); // 86400000 ms = 1 day
  return jsDate;
};
